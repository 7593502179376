// 权限管理
<template>
    <div class="page_container">
        <div class="bgc_FFFFFF flex fn_size14 height_44">
            <div v-for="item in tabArray" :key="item.id" class="tab" :class="tabIndex==item.id?'tab_active':''"
                @click="changeTab(item.id)">{{item.label}}</div>
        </div>
        <!-- 用户管理--开始  tabIndex==0 -->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30" v-if="tabIndex==0">
            <div class="pdt_20 flex justify_between">
                <div class="flex">
                    <el-select v-model="value" placeholder="请选择用户名字" class="width_170">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input v-model="value" placeholder="输入搜索关键字" class="width_170 mg_left_10"></el-input>
                    <el-button type="primary" class="mg_left_10 select_btn">查询</el-button>
                </div>
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="mg_left_10 select_btn"
                    style="background: #03CABE;border:none;" @click="add">增加</el-button>
            </div>
            <el-table :data="queryList" class="mg_top_20" height="570" border>
                <el-table-column type="index" label="序号" width="55"></el-table-column>
                <el-table-column prop="userName" label="用户名" width="">
                </el-table-column>
                <el-table-column prop="userAccount" label="用户账号" width="">
                </el-table-column>
                <el-table-column prop="workNumber" label="用户工号" width="">
                </el-table-column>
                <el-table-column label="性别" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.gender == 1">男</span>
                        <span v-if="scope.row.gender == 2">女</span>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="用户电话" width="">
                </el-table-column>
                <el-table-column prop="email" label="邮箱" width="">
                </el-table-column>
                <el-table-column prop="departmentID" label="用户部门" width="">
                </el-table-column>
                <el-table-column prop="jobTitle" label="职位" width="">
                </el-table-column>
                <el-table-column prop="createDate" label="创建时间" width="">
                </el-table-column>
                <el-table-column label="是否离职" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.leaveFlag == 0">未离职</span>
                        <span v-if="scope.row.leaveFlag == 1">已离职</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lockFlag" label="是否锁定" width="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.lockFlag == 0">未锁定</span>
                        <span v-if="scope.row.lockFlag == 1">已锁定</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="220">
                    <template slot-scope="scope">
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0" @click="gotoModify(scope.row)">
                            编辑
                            <!-- <span style="border-bottom: 1px solid #0384D5;">追踪</span> -->
                        </el-button>
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0" @click="look(scope.row)">查看
                        </el-button>
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0"
                            @click="lock(scope.row.userID)">锁定
                        </el-button>
                        <el-button type="text" class="color_009BFD width_40 pdt_0 pdb_0"
                            @click="delTableData(scope.row.userID)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="flex justify_end  ">
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <span>这是一段信息</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog> -->
        <!-- 新增用户信息弹出框 -->
        <el-dialog :visible.sync="dialogVisible" top='18vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">用户信息配置</div>
            <div class="">
                <!-- pdl30 pdr30 -->
                <el-form :model="addFrom" :label-width="labelWidth" :rules="rulesFrom" class="mg_top_30 flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="用户名：">
                        <el-input v-model="addFrom.userName" placeholder="用户名" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="性别：">
                        <el-select v-model="addFrom.gender" placeholder="性别" class="width_170 ">
                            <el-option v-for="item in sex" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职位：">
                        <el-input v-model="addFrom.jobTitle" placeholder="职位" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="工号：">
                        <el-input v-model="addFrom.workNumber" placeholder="工号" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="账号：">
                        <el-input v-model="addFrom.userAccount " placeholder="账号" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="密码：">
                        <el-input v-model="addFrom.userPassword" placeholder="密码" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：">
                        <el-input v-model="addFrom.email" placeholder="邮箱" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="电话：">
                        <el-input v-model="addFrom.phone" placeholder="电话" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="部门ID: ">
                        <el-input v-model="addFrom.departmentID" placeholder="部门ID" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="是否在职：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="addFrom.leaveFlag" class="width_170 text_align_left">
                            <el-radio label=1>是</el-radio>
                            <el-radio label=0>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否锁定：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="addFrom.lockFlag" class=" text_align_left"
                            @change="changeLockDateReadOnly">
                            <el-radio label=1>是</el-radio>
                            <el-radio label=0>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="锁定日期：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-date-picker v-model="addFrom.lockDate" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="width_170" :disabled="lockDateReadOnly">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="addFrom" :label-width="labelWidth" :rules="rulesFrom" class="">
                    <el-form-item label="描述：">
                        <el-input type="textarea" :rows="4" v-model="addFrom.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="trueadd">添加</el-button>
            </div>
        </el-dialog>
        <!--   用户信息--编辑弹出框 -->
        <el-dialog :visible.sync="dialog2Visible" top='3vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑用户信息</div>
            <div class="flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="updateForm" :label-width="labelWidth" :rules="rulesFrom"
                    class="mg_top_30 flex flex_wrap">
                    <!-- justify_around -->
                    <!--                     <el-form-item label="用户名：">
                        <el-input v-model="addFrom.userName" placeholder="用户名" class="width_170 " :readonly="true"></el-input>
                    </el-form-item> -->
                    <el-form-item label="性别：">
                        <el-select v-model="updateForm.gender" placeholder="性别" class="width_170 ">
                            <el-option v-for="item in sex" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="职位：">
                        <el-input v-model="updateForm.jobTitle" placeholder="职位" class="width_170 "></el-input>
                    </el-form-item>
                    <!--                     <el-form-item label="工号：">
                        <el-input v-model="addFrom.workNumber" placeholder="工号" class="width_170 " :readonly="true"></el-input>
                    </el-form-item> -->
                    <!--                     <el-form-item label="账号：">
                        <el-input v-model="addFrom.userAccount " placeholder="账号" class="width_170 " :readonly="true"></el-input>
                    </el-form-item> -->
                    <el-form-item label="密码：">
                        <el-input v-model="updateForm.userPassword" placeholder="密码" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：">
                        <el-input v-model="updateForm.email" placeholder="邮箱" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="电话：">
                        <el-input v-model="updateForm.phone" placeholder="电话" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="部门ID: ">
                        <el-input v-model="updateForm.departmentID" placeholder="部门ID" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="是否在职：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="updateForm.leaveFlag" class="width_170 text_align_left">
                            <el-radio :label='1'>是</el-radio>
                            <el-radio :label='0'>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否锁定：">
                        <!-- <el-input v-model="newFrom.author" placeholder="文档作者" class="width_170 flex"></el-input> -->
                        <el-radio-group v-model="updateForm.lockFlag" class=" text_align_left"
                            @change="changeLockDateReadOnly">
                            <el-radio :label='1'>是</el-radio>
                            <el-radio :label='0'>否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <el-form :model="updateForm" :label-width="labelWidth" :rules="rulesFrom" class="">
                    <el-form-item label="描述：">
                        <el-input type="textarea" :rows="4" v-model="updateForm.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>
                <div>
                    <div class="border_bottom_1_CCCCCC height_40 mg_bottom_20">
                        <div class="fn_size18 color_000000 flex justify_center font_bold">用户-角色配置</div>
                    </div>
                    <el-col :span="10" class="shuttleBox">
                        <el-table :data="userNotHaveRoleArray" border ref="selection" @selection-change="checkAll"
                            :header-cell-style="tableHeaderColor" height="100%" class="width_100"
                            style="overflow-y: auto;">
                            <el-table-column type="selection" width="50"></el-table-column>
                            <el-table-column prop="name" label="系统所有角色">
                                <template slot-scope="scope">
                                    <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                        style="border: 1px solid #E6E6E6;">
                                        {{scope.row.name}}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="4">
                        <div class="flex flex_column justify_center align_center shuttleBox">
                            <div @click="addUserRole()" class="mg_bottom_10">
                                <el-button :disabled="userNotHaveRoleArray.length?false:true" :size="buttonSize"
                                    style="background: #EBEBEB;border:none" type="primary">
                                    <i class="">
                                        <img src="../../assets/images/systemConfiguration/user4.png">
                                    </i>
                                    <!-- 添加 -->
                                </el-button>
                            </div>
                            <div class="spacing" @click="removeUserRole()">
                                <el-button :disabled="userHaveRoleArray.length?false:true" :size="buttonSize"
                                    style="background: #EBEBEB;border:none" type="primary">
                                    <i class="">
                                        <img src="../../assets/images/systemConfiguration/user5.png">
                                    </i>
                                    <!-- 添加 -->
                                </el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="10" class="shuttleBox">
                        <el-table :data="userHaveRoleArray" border ref="selection" @selection-change="checkRightAll"
                            :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                            style="overflow-y: auto;">
                            <el-table-column type="selection" width="50"></el-table-column>
                            <el-table-column prop="name" label="当前用户角色">
                                <template slot-scope="scope">
                                    <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                        style="border: 1px solid #E6E6E6;">
                                        {{scope.row.name}}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </div>
            </div>

            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="trueModify">确定</el-button>
            </div>
        </el-dialog>

        <!-- 查看用户信息弹出框 -->
        <el-dialog :visible.sync="dialog3Visible" top='3vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">用户信息配置</div>
            <div class="">
                <!-- pdl30 pdr30 -->
                <el-form :model="detailForm" :label-width="labelWidth" :rules="rulesFrom"
                    class="mg_top_30 flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="用户名：">
                        <el-input v-model="detailForm.userName" placeholder="用户名" class="width_170 " :readonly="true">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="性别：">
                        <!--  <el-input v-model="detailForm.gender" placeholder="性别" class="width_170 " :readonly="true"></el-input> -->
                        <el-input value="男" v-if="detailForm.gender == 0" placeholder="性别" class="width_170 "
                            :readonly="true"></el-input>
                        <el-input value="女" v-if="detailForm.gender == 1" placeholder="性别" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="职位：">
                        <el-input v-model="detailForm.jobTitle" placeholder="职位" class="width_170 " :readonly="true">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="工号：">
                        <el-input v-model="detailForm.workNumber" placeholder="工号" class="width_170 " :readonly="true">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="账号：">
                        <el-input v-model="detailForm.userAccount " placeholder="账号" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="密码：">
                        <el-input v-model="detailForm.userPassword" placeholder="密码" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱：">
                        <el-input v-model="detailForm.email" placeholder="邮箱" class="width_170 " :readonly="true">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="电话：">
                        <el-input v-model="detailForm.phone" placeholder="电话" class="width_170 " :readonly="true">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="部门ID: ">
                        <el-input v-model="detailForm.departmentID" placeholder="部门ID" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="是否在职：">
                        <el-input value="未离职" v-if="detailForm.leaveFlag == 0" placeholder="是否在职" class="width_170 "
                            :readonly="true"></el-input>
                        <el-input value="已离职" v-if="detailForm.leaveFlag == 1" placeholder="是否在职" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="是否锁定：">
                        <el-input value="未锁定" v-if="detailForm.lockFlag == 0" placeholder="是否锁定" class="width_170 "
                            :readonly="true"></el-input>
                        <el-input value="已锁定" v-if="detailForm.lockFlag == 1" placeholder="是否锁定" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                    <el-form-item label="锁定日期：">
                        <el-input v-model="detailForm.createDate" placeholder="锁定日期" class="width_170 "
                            :readonly="true"></el-input>
                    </el-form-item>
                </el-form>
                <el-form :model="detailForm" :label-width="labelWidth" :rules="rulesFrom" class="">
                    <el-form-item label="描述：">
                        <el-input type="textarea" :rows="4" v-model="detailForm.description" resize="none"
                            placeholder="请输入介绍信息" class="" :readonly="true"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="closedialog3">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 用户管理--结束 -->


        <!-- 用户组--开始 -->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" v-if="tabIndex==1">
            <!-- 左侧 用户组管理菜单 -->
            <div class=" width_346 ">
                <el-table :header-cell-style="{textAlign: 'left'}" :cell-style="{ textAlign: 'left' }"
                    :data="roleGroupArray" style="width: 100%" height="600" highlight-current-row
                    @current-change="showDetails">
                    <el-table-column label="用户组管理" width="230">
                        <template slot-scope="scope">
                            <span class="mg_left_20">{{scope.row.userGroupName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" width="130">
                        <template slot-scope="scope">
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="250" trigger="hover">
                                    <div>创建时间：{{scope.row.userGroupCreateDate}}</div>
                                    <div>描述：{{scope.row.userGroupDesc}}</div>
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="roleGroupEdit(scope.row)">
                                <img src="../../assets/images/systemConfiguration/user14.png" class="mg_right_16"
                                    @click="roleGroupDel(scope.row.userGroupID)">
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 添加用户组按钮 -->
                <el-button type="primary" class="width_100 height_40 flex justify_center align_center"
                    @click="addRoleGroup">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加用户组</span>
                </el-button>
            </div>
            <!-- 右侧 用户组配置 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 用户组配置 标题 -->
                <div class="fn_size20 width_826 flex justify_between pdb_20 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="userGroupDetailsShowControl(true)"></el-button>
                    <span v-if="userGroupDetailsShow == 1" class="flex justify_center align_center"
                        style="font-weight: bold;">用户组-角色配置</span>
                    <span v-if="userGroupDetailsShow == 2" class="flex justify_center align_center"
                        style="font-weight: bold;">用户组-用户配置</span>
                    <span v-if="userGroupDetailsShow == 3" class="flex justify_center align_center"
                        style="font-weight: bold;">用户组-组织配置</span>
                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="userGroupDetailsShowControl(false)">
                    </el-button>
                </div>
                <!-- 穿梭框 -->
                <div class="width_826 height_100 mg_top_22">
                    <div v-if="userGroupDetailsShow == 1">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterRoleData" border ref="selection"
                                @selection-change="checkAll" :header-cell-style="tableHeaderColor"
                                class="height_444 width_100" style="overflow-y: auto;">
                                <el-table-column prop="name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterRoleData" border @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                                <el-table-column prop="name" label="当前用户组的角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="userGroupDetailsShow == 2">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterUserData" border @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                                <el-table-column prop="name" label="系统所有用户">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterUserData" border @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                                <el-table-column prop="name" label="当前用户组的用户">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="userGroupDetailsShow == 3">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterGroupData" border @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                                <el-table-column prop="meter_name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterGroupData" border @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                                <el-table-column prop="meter_name" label="当前用户组的组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <!--                                             <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div> -->
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>

                <!-- 添加按钮 -->
                <!-- <el-button type="primary" class="width_110 height_36 mg_top_30 flex justify_center align_center">添加
                </el-button> -->
            </div>
        </div>

        <!-- 用户组--添加角色组弹框 roleGroup -->
        <el-dialog :visible.sync="dialogVisibleRoleGroupAdd" top='25vh' width="40%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加用户组</div>
            <div class="pdr20">
                <!-- pdl30 pdr30 -->
                <el-form :model="addRoleGroupForm" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <el-form-item label="用户组名：" prop="userGroupName">
                        <el-input v-model="addRoleGroupForm.userGroupName" placeholder="请输入用户组名" class="width_170 ">
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-form :model="addRoleGroupForm" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="userGroupDescription">
                        <el-input type="textarea" :rows="4" v-model="addRoleGroupForm.userGroupDescription"
                            resize="none" placeholder="请输入用户组描述" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAdd">添加</el-button>
            </div>
        </el-dialog>
        <!-- 用户组-编辑 -->
        <el-dialog :visible.sync="dialogVisibleRoleGroupEdit" top='5vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">用户组信息配置</div>
            <div class="pdl20 pdr20 flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="updateRoleGroupForm" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="用户组名：" prop="userGroupName">
                        <el-input v-model="updateRoleGroupForm.userGroupName" placeholder="请输入用户组名" class="width_170 ">
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-form :model="updateRoleGroupForm" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="description">
                        <el-input type="textarea" :rows="4" v-model="updateRoleGroupForm.description" resize="none"
                            placeholder="请输入用户组描述" class=""></el-input>
                    </el-form-item>
                </el-form>

                <!-- 用户组-用户组配置--穿梭框 -->
                <div class="fn_size20 flex justify_between align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="userGroupDetailsShowControl(true)">
                    </el-button>
                    <div v-if="userGroupDetailsShow == 1" class="fn_size18 color_000000 flex justify_center font_bold">
                        用户组-角色配置</div>
                    <div v-if="userGroupDetailsShow == 2" class="fn_size18 color_000000 flex justify_center font_bold">
                        用户组-用户配置</div>
                    <div v-if="userGroupDetailsShow == 3" class="fn_size18 color_000000 flex justify_center font_bold">
                        用户组-组织配置</div>
                    <el-button icon="el-icon-arrow-right el-icon--right"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="userGroupDetailsShowControl(false)">
                    </el-button>
                </div>
                <div class="mg_top_20">
                    <div v-if="userGroupDetailsShow == 1">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterRoleData" border ref="selection"
                                @selection-change="selectBandRoleID" :header-cell-style="tableHeaderColor" height="100%"
                                class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="bandRoleID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary" @click="bandRole">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="unbandRoleID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary" @click="unbandRole">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterRoleData" border ref="selection"
                                @selection-change="selectUnbandRoleID" :header-cell-style="tableHeaderColor"
                                height="100%" class=" width_100" style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前用户组的角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="userGroupDetailsShow == 2">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterUserData" border ref="selection"
                                @selection-change="selectBandUserID" :header-cell-style="tableHeaderColor" height="100%"
                                class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有用户">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="bandUserID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary" @click="bandUser">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="unbandUserID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary" @click="unbandUser">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterUserData" border ref="selection"
                                @selection-change="selectUnbandUserID" :header-cell-style="tableHeaderColor"
                                height="100%" class=" width_100" style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前用户组的用户">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>

                    <div v-if="userGroupDetailsShow == 3">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterGroupData" border ref="selection"
                                @selection-change="selectBandOrganizationID" :header-cell-style="tableHeaderColor"
                                height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="bandOrganizationID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="bandOrganization">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="unbandOrganizationID?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="unbandOrganization">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterGroupData" border ref="selection"
                                @selection-change="selectUnbandOrganizationID" :header-cell-style="tableHeaderColor"
                                height="100%" class=" width_100" style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前用户组的组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>

                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="trueEdit">更新</el-button>
            </div>
        </el-dialog>
        <!-- 用户组--结束 -->

        <!-- 角色管理--开始   tabIndex==2-->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" v-if="tabIndex==2">
            <!-- 左侧 角色管理菜单 -->
            <div class=" width_346 ">
                <el-card class="width_346 flex flex_column" style="height: 600px; overflow-y: auto;">
                    <!-- 标题 -->
                    <div slot="header" class="fn_size14 flex">
                        <span>角色管理</span>
                    </div>
                    <!-- 角色列表 -->
                    <div v-for="item in roleArray" :key="item.id" :label="item.name" :value="item.id" class="fn_size12">
                        <div class="flex align_center justify_between mg_bottom_10 pdl10 pdr10 height_30"
                            style="border: 1px solid #E6E6E6;">
                            {{item.name}}
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="200" trigger="hover">
                                    <div>创建信息：</div>{{tools.formatDate(item.createDate, 'yyyy-MM-dd hh:mm:ss')}}
                                    <div>描述：</div>{{item.description}}
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="roleEdit">
                                <img src="../../assets/images/systemConfiguration/user13.png" class="mg_right_16"
                                    @click="roleLocking">
                                <img src="../../assets/images/systemConfiguration/user14.png" class="" @click="roleDel">
                            </div>
                        </div>
                    </div>
                </el-card>
                <!-- 添加角色按钮 -->
                <el-button type="primary" class="width_100 height_40 flex justify_center align_center" @click="addRole">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加角色</span>
                </el-button>
            </div>
            <!-- 右侧 角色组配置 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 角色组配置 标题 -->
                <div class="fn_size20 width_826 flex justify_between pdb_20 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="roleDetailsShow=!roleDetailsShow"></el-button>
                    <span v-if="roleDetailsShow" class="flex justify_center align_center"
                        style="font-weight: bold;">角色-角色组配置</span>
                    <span v-if="!roleDetailsShow" class="flex justify_center align_center"
                        style="font-weight: bold;">角色-组织配置</span>
                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="roleDetailsShow=!roleDetailsShow"></el-button>
                </div>
                <!-- 穿梭框 -->

                <div class="width_826 height_100 mg_top_22">
                    <div v-if="roleDetailsShow">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前用户角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="!roleDetailsShow">
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前用户角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>

                <!-- 添加按钮 -->
                <!-- <el-button type="primary" class="width_110 height_36 mg_top_30 flex justify_center align_center">添加
                </el-button> -->
            </div>
        </div>

        <!-- 角色管理-弹窗 -->
        <!-- 角色管理--添加角色弹窗 -->
        <el-dialog :visible.sync="dialogVisibleRoleAdd" top='25vh' width="40%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">添加角色</div>
            <div class="pdr20">
                <!-- pdl30 pdr30 -->
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <el-form-item label="角色名：" prop="fileName">
                        <el-input v-model="fromRole.fileName" placeholder="请输入" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="创建日期：" prop="author">
                        <el-date-picker v-model="fromRole.value1" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="fromRole.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAdd">添加</el-button>
            </div>
        </el-dialog>
        <!-- 角色管理--编辑 -->
        <el-dialog :visible.sync="dialogVisibleRoleEdit" top='3vh' width="45%">
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">角色信息配置</div>
            <div class="pdl20 pdr20 flex flex_column">
                <!-- pdl30 pdr30 -->
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom"
                    class="mg_top_30  flex flex_wrap">
                    <!-- justify_around -->
                    <el-form-item label="角色名：" prop="fileName">
                        <el-input v-model="fromRole.fileName" placeholder="文档名称" class="width_170 "></el-input>
                    </el-form-item>
                    <el-form-item label="创建日期：" prop="author">
                        <el-date-picker v-model="fromRole.value1" type="datetime" :clearable="false"
                            placeholder="选择日期时间" class="">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-form :model="fromRole" :label-width="labelWidth2" :rules="rulesFrom" class="">
                    <el-form-item label="描述：" prop="user">
                        <el-input type="textarea" :rows="4" v-model="fromRole.description" resize="none"
                            placeholder="请输入介绍信息" class=""></el-input>
                    </el-form-item>
                </el-form>

                <!-- 角色-角色组配置--穿梭框 -->
                <div class="fn_size20 flex justify_between align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <el-button icon="el-icon-arrow-left"
                        class="height_36 width_36 check_btn flex justify_center align_center"
                        @click="roleStause=!roleStause"></el-button>
                    <div v-if="roleStause" class="fn_size18 color_000000 flex justify_center font_bold">角色-角色组配置</div>
                    <div v-if="!roleStause" class="fn_size18 color_000000 flex justify_center font_bold">角色-组织配置</div>

                    <el-button class="height_36 width_36 check_btn flex justify_center align_center"
                        icon="el-icon-arrow-right el-icon--right" @click="roleStause=!roleStause"></el-button>
                </div>
                <div class="mg_top_20">
                    <div v-if="roleStause">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有角色组">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前角色组">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                    <div v-if="!roleStause">
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterData" border ref="selection" @selection-change="checkAll"
                                :header-cell-style="tableHeaderColor" height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterData" border ref="selection" @selection-change="checkRightAll"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="meter_name" label="当前角色组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.meter_name}}
                                            <div class="flex align_center">
                                                <img src="../../assets/images/systemConfiguration/user14.png" class="">
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="onAdd">添加</el-button>
            </div>
        </el-dialog>
        <!-- 角色管理--结束 -->

        <!-- 组织 --开始 -->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" v-if="tabIndex==3">
            <!-- 左侧 组织管理菜单 -->
            <div class=" width_346 ">
                <el-table :header-cell-style="{textAlign: 'left'}" :cell-style="{ textAlign: 'left' }"
                    :data="organizationArray" style="width: 100%" height="600" highlight-current-row
                    @current-change="showOrganizationDetails">
                    <el-table-column label="组织管理" width="230">
                        <template slot-scope="scope">
                            <span class="mg_left_20">{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" width="130">
                        <template slot-scope="scope">
                            <div class="flex align_center">
                                <el-popover placement="top-start" title="" width="250" trigger="hover">
                                    <div>创建时间：{{scope.row.createDate}}</div>
                                    <div>描述：{{scope.row.description}}</div>
                                    <img slot="reference" src="../../assets/images/systemConfiguration/user10.png"
                                        class="mg_right_16">
                                </el-popover>
                                <img src="../../assets/images/systemConfiguration/user12.png" class="mg_right_16"
                                    @click="organizationEdit(scope.row)">
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 右侧 组织配置 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 组织配置 标题 -->
                <div class="fn_size20 width_826 flex justify_center pdb_20 border_bottom_1_CCCCCC">
                    <span class="flex justify_center align_center" style="font-weight: bold;">组织-角色配置
                    </span>
                </div>
                <!-- 穿梭框 -->
                <div class="width_826 height_100 mg_top_22">
                    <div>
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterRoleDataByOrganizationID" border ref="selection"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column prop="name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterRoleDataByOrganization" border ref="selection"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column prop="name" label="当前组织角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>

        <!-- 组织管理-弹窗 -->
        <!-- 组织管理--编辑--弹窗-->
        <el-dialog :visible.sync="dialogVisibleOrganizationEdit" top='3vh' width="45%">
            <div class="pdl20 pdr20 flex flex_column">
                <!-- --穿梭框 -->
                <div class="fn_size20 flex justify_center align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <div class="fn_size18 color_000000 flex justify_center font_bold">组织-角色配置</div>
                </div>
                <div class="mg_top_20">
                    <div>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterRoleDataByOrganizationID" border ref="selection"
                                @selection-change="selectBandRoleID_Organization" :header-cell-style="tableHeaderColor"
                                height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="bandRoleID_Organization?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="bandRole_Organization">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="unbandRoleID_Organization?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="unbandRole_Organization">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterRoleDataByOrganization" border ref="selection"
                                @selection-change="selectUnbandRoleID_Organization"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前组织角色">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="dialogVisibleOrganizationEdit = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 组织 --结束 -->

        <!-- 部门职位--开始 -->
        <div class="bgc_FFFFFF mg_top_20 pdl30 pdr30 flex pdt_20 pdb_30" v-if="tabIndex==6">
            <!-- 左侧 部门职位菜单 -->
            <div class=" width_450 ">
                <div class="flex align_center fn_size14">
                    <span>部门：</span>
                    <el-select v-model="departmentID_departmentJob" placeholder="请选择部门" @change="queryDepartmentJob">
                        <el-option v-for="item in departmentSelectList" :key="item.departmentID" :label="item.departmentName" :value="item.departmentID">
                        </el-option>
                    </el-select>
                </div>
                <el-table :header-cell-style="{textAlign: 'left'}" :cell-style="{ textAlign: 'left' }" class="mg_top_10 width_100"
                    :data="departmentJobArray"  height="600" highlight-current-row
                    @current-change="showDepartmentJobDetails">
                    <el-table-column label="职位" >
                        <template slot-scope="scope">
                            <span class="mg_left_20">{{scope.row.jobName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" width="60">
                        <template slot-scope="scope">
                            <div class="flex align_center">
                                <img src="../../assets/images/systemConfiguration/user12.png"
                                    @click="departmentJobEdit(scope.row)">
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 右侧 部门职位 穿梭框 -->
            <div class="pdt_30 width_100 mg_left_30 flex flex_column justify_between align_center">
                <!-- 部门职位 标题 -->
                <div class="fn_size20 width_826 flex justify_center pdb_20 border_bottom_1_CCCCCC">
                    <span class="flex justify_center align_center" style="font-weight: bold;">部门职位-组织配置
                    </span>
                </div>
                <!-- 穿梭框 -->
                <div class="width_826 height_100 mg_top_22">
                    <div>
                        <el-col :span="10">
                            <el-table :data="departmentAndMeterOrganizationDataByDepartmentJob" border ref="selection"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column prop="name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center height_444">
                                <div @click="handelSelect" class="mg_bottom_10">
                                    <el-button :disabled="nowSelectData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i>
                                        <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing" @click="handleRemoveSelect">
                                    <el-button :disabled="nowSelectRightData.length?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i>
                                        <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <el-table :data="otherMeterOrganizationDataByDepartmentJob" border ref="selection"
                                :header-cell-style="tableHeaderColor" class="height_444 width_100"
                                style="overflow-y: auto;">
                                <el-table-column prop="name" label="当前部门职位关联的组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>

        <!-- 部门职位-弹窗 -->
        <!--部门职位管理--编辑--弹窗-->
        <el-dialog :visible.sync="dialogVisibleDepartmentEdit" top='3vh' width="45%">
            <div class="pdl20 pdr20 flex flex_column">
                <!-- --穿梭框 -->
                <div class="fn_size20 flex justify_center align_center mg_top_30 pdb_10 border_bottom_1_CCCCCC">
                    <div class="fn_size18 color_000000 flex justify_center font_bold">部门职位-组织配置</div>
                </div>
                <div class="mg_top_20">
                    <div>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="departmentAndMeterOrganizationDataByDepartmentJob" border ref="selection"
                                @selection-change="selectBandOrganizationID_DepartmentJob" :header-cell-style="tableHeaderColor"
                                height="100%" class="width_100">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="系统所有组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between  pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="4">
                            <div class="flex flex_column justify_center align_center shuttleBox">
                                <div class="mg_bottom_10">
                                    <el-button :disabled="bandOrganizationID_DepartmentJob?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="bandOrganization_DepartmentJob">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user4.png">
                                        </i> <!-- 移除 -->
                                    </el-button>
                                </div>
                                <div class="spacing">
                                    <el-button :disabled="unbandOrganizationID_DepartmentJob?false:true" :size="buttonSize"
                                        style="background: #EBEBEB;border:none" type="primary"
                                        @click="unbandOrganization_DepartmentJob">
                                        <i class="">
                                            <img src="../../assets/images/systemConfiguration/user5.png">
                                        </i> <!-- 添加 -->
                                    </el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="10" class="shuttleBox">
                            <el-table :data="otherMeterOrganizationDataByDepartmentJob" border ref="selection"
                                @selection-change="selectUnbandOrganizationID_DepartmentJob"
                                :header-cell-style="tableHeaderColor" height="100%" class=" width_100"
                                style="overflow-y: auto;">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="name" label="当前部门职位关联的组织">
                                    <template slot-scope="scope">
                                        <div class="flex align_center justify_between pdl10 pdr10 height_30"
                                            style="border: 1px solid #E6E6E6;">
                                            {{scope.row.name}}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="flex justify_center pdb_20 pdt_20">
                <el-button type="primary" class="addBtn" @click="dialogVisibleDepartmentEdit = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 部门职位--结束 -->


    </div>
</template>
<script>
    import {
        userConfigUserList,
        userConfigCreateUser,
        userConfigDeleteUser,
        userConfigUpdateUser,
        userConfigLockUser,
        userConfigQueryRole,
        userConfigQueryUserCorrelationRole,
        userConfigBandingUserAndRole,
        userConfigUnBandingUserAndRole,
        roleConfigCreateRole,
        roleConfigUpdateRole,
        roleConfigDeleteRole,
        userConfigQueryRoleGroup,
        userConfigQueryRoleCorrelationRoleGroup,
        roleConfigBandingRoleGroupAndRole,
        roleConfigUnBandingRoleGroupAndRole,
        userConfigQueryOrganization,
        userConfigQueryRoleCorrelationOrganization,
        roleConfigBandingOrganizationAndRole,
        roleConfigUnBandingOrganizationAndRole,
        roleConfigCreateRoleGroup,
        roleConfigUpdateRoleGroup,
        roleConfigDeleteRoleGroup,
        userConfigQueryPermission,
        userConfigQueryRoleGroupCorrelationPermission,
        roleConfigBandingPermissionAndRoleGroup,
        roleConfigUnBandingPermissionAndRoleGroup,
        userConfigQueryPage,
        userConfigQueryRoleGroupCorrelationPage,
        roleConfigBandingPageAndRoleGroup,
        roleConfigUnBandingPageAndRoleGroup,
        userConfigGenderSelect,
        userConfigJobSelect,

        userConfigDepartmentSelect,// 部门选择框


        //权限管理-用户组
        //author：刘勇文
        userConfigUserGroupSelect, //查找用户组
        roleConfigCreateUserGroup, //添加用户组接口
        roleConfigUpdateUserGroup, //更新用户组
        roleConfigDeleteUserGroup, //删除用户组
        userConfigQueryUserByUserGroupID, //根据用户组Id查询系统用户
        userConfigQueryOrganizationByUserGroupID, //根据用户组ID查询系统组织
        userConfigQueryRoleByUserGroupID, //根据用户组ID查询系统角色
        userConfigQueryUserGroupCorrelationUser, //根据用户组ID查询用户组关联的用户
        userConfigQueryUserGroupCorrelationRole, //根据用户组ID查询用户组关联的角色
        userConfigQueryUserGroupCorrelationOrganization, //根据用户组ID查询用户组关联的组织
        userConfigBandingUserGroupAndRole, //绑定用户组和角色
        userConfigUnBandingUserGroupAndRole, //解绑用户组和角色
        userConfigBandingUserGroupAndUser, //绑定用户组和用户
        userConfigUnBandingUserGroupAndUser, //解绑用户组和用户
        userConfigBandingUserGroupAndOrganization, //绑定用户组和组织
        userConfigUnBandingUserGroupAndOrganization, //解绑用户组和组织

        //权限管理-组织
        //author：刘勇文
        userConfigOrganizationSelect, //查询组织
        userConfigQueryRoleByUserOrganizationID, // 根据组织ID查询当前系统的角色
        userConfigQueryOrganizationCorrelationRole, // 根据组织ID查询当前组织关联的角色
        userConfigBandingOrganizationAndRole, // 绑定组织和角色
        userConfigUnBandingOrganizationAndRole, //解绑组织和角色

        //权限管理-部门职位
        //author：刘勇文
        userConfigDepartmentJobSelect,// 部门职位选择框
        userConfigQueryOrganizationByUserDepartmentJobID,//根据部门职位ID查询系统组织
        userConfigQueryDepartmentJobCorrelationOrganization,// 查询部门职位关联的组织
        userConfigBandingDepartmentJobAndOrganization,// 解绑部门职位和组织
        userConfigUnBandingDepartmentJobAndOrganization,// 解绑部门职位和组织

    } from "@/api/api_systemConfig";
    export default {
        data() {
            const generateData = _ => {
                const data = [];
                for (let i = 1; i <= 15; i++) {
                    data.push({
                        key: i,
                        label: `备选项 ${ i }`,
                        disabled: i % 4 === 0
                    });
                }
                return data;
            };
            return {
                // data: generateData(),
                value1: [],
                labelWidth: '90px',
                labelWidth2: '90px',
                tabIndex: 0,
                newFrom: {},
                fromRole: {}, //角色管理-from
                addFrom: {
                    userName: '',
                    gender: '',
                    jobTitle: '',
                    workNumber: '',
                    userAccount: '',
                    phone: '',
                    email: '',
                    departmentID: '',
                    leaveFlag: '',
                    lockFlag: '',
                    lockDate: '',
                    userPassword: '',
                    description: '',
                    userID: ''
                },
                updateForm: {
                    userID: '',
                    gender: '',
                    jobTitle: '',
                    phone: '',
                    email: '',
                    departmentID: '',
                    leaveFlag: '',
                    lockFlag: '',
                    userPassword: '',
                    description: ''
                },
                detailForm: {
                    userName: '',
                    gender: '',
                    jobTitle: '',
                    workNumber: '',
                    userAccount: '',
                    phone: '',
                    email: '',
                    departmentID: '',
                    leaveFlag: '',
                    lockFlag: '',
                    lockDate: '',
                    userPassword: '',
                    description: '',
                    userID: ''
                },
                lockDateReadOnly: false,
                dialogVisible: false, //新增用户弹出框 打开/关闭 控制标志
                dialog2Visible: false, //修改用户弹出框 打开/关闭 控制标志
                dialog3Visible: false, //查看用户弹出框 打开/关闭 控制标志
                dialogVisibleRoleAdd: false, //角色管理-新增角色弹窗
                dialogVisibleRoleEdit: false,
                tabArray: [{
                        id: 0,
                        label: '用户管理'
                    },
                    {
                        id: 1,
                        label: '用户组'
                    }, {
                        id: 2,
                        label: '角色管理'
                    },
                    //  {
                    //     id: 2,
                    //     label: '权限分配'
                    // },

                    //  {
                    //     id: 4,
                    //     label: '页面管理'
                    // },
                    {
                        id: 3,
                        label: '组织'
                    },
                    {
                        id: 4,
                        label: '部门'
                    },
                    {
                        id: 5,
                        label: '职位'
                    }, {
                        id: 6,
                        label: '部门职位'
                    }
                ],
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }],
                sex: [],
                jobArray: [],
                departmentArray: [],
                value: '',
                tableData: [],
                multipleSelection: [],
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                deleteNum: '',
                rulesFrom: {},
                // 角色管理-开始
                roleArray: [], // 角色列表
                userHaveRoleArray: [], // 用户已有角色列表
                userNotHaveRoleArray: [], // 用户没有角色列表
                // 角色管理-结束
                // 角色组-开始
                // 角色组-结束
                // 穿梭框-开始
                buttonSize: 'large',
                nowSelectData: [], // 左边选中列表数据
                nowSelectRightData: [], // 右边选中列表数据    
                departmentAndMeterData: [{
                    meter_name: '系统管理员',
                }, {
                    meter_name: '设备经理',
                }, {
                    meter_name: '分厂厂长',
                }, {
                    meter_name: '集团',
                }, {
                    meter_name: '工段长',
                }, {
                    meter_name: '工艺技术部',
                }, {
                    meter_name: '维修班长',
                }], //系统所有角色（左侧）(数据格式自定义或者从后台获取)    
                otherMeterData: [], //当前用户角色（右侧）（数据格式自定义或者从后台获取）
                //穿梭框-结束
                query: {
                    "typeID": 1,
                    "text": ''
                },
                queryList: [],
                lockUserID: '',
                roleDetailsShow: true,
                roleStause: true,
                userID: '',
                fromRoleGroup: {},


                //用户组模块-使用的相关变量
                //author：刘勇文
                //功能1 查询用户组
                roleGroupArray: [], //查询用户组所用数组


                //功能2 添加用户组
                dialogVisibleRoleGroupAdd: false, //添加用户组弹框 显示/关闭 控制标识符

                addRoleGroupForm: {
                    userGroupName: '', //用户组名
                    userGroupDescription: '', //用户组描述
                }, //添加用户组所用的对象

                //功能3 删除用户组
                deleteUserGroupID: '', //要删除的用户组ID

                //功能4 更新用户组信息
                dialogVisibleRoleGroupEdit: false, //更新用户组信息弹框 显示/关闭 控制标识符

                updateRoleGroupForm: {
                    userGroupID: '', //用户组ID
                    userGroupName: '', //用户组名
                    description: '', //用户组描述
                }, //跟新用户组所用的对象

                //功能5 首页展示穿梭框循环展示控制符
                userGroupDetailsShow: 2,

                //功能6
                showDetailsuserGroupID: '', //要展示用户组配置详情的用户组id

                departmentAndMeterUserData: [], //根据用户组ID展示当前系统的用户 所用的数组
                departmentAndMeterRoleData: [], //根据用户组ID展示当前系统的角色 所用的数组
                departmentAndMeterGroupData: [], //根据用户组ID展示当前系统的组织 所用的数组

                otherMeterUserData: [], //根据用户组ID展示用户组关联的用户 所用的数组
                otherMeterRoleData: [], //根据用户组ID展示用户组关联的角色 所用的数组
                otherMeterGroupData: [], //根据用户组ID展示用户组关联的组织 所用的数组

                //功能7
                bandUserGroupID: '', //要绑定的目标用户组ID
                unbandUserGroupID: '', //要解绑的目标用户组ID

                //功能7
                bandRoleID: '', //要绑定的目标角色ID
                unbandRoleID: '', //要解绑的目标角色ID

                //功能8
                bandUserID: '', //要绑定的目标用户ID
                unbandUserID: '', //要解绑的目标用户ID

                //功能9
                bandOrganizationID: '', //要绑定的目标组织ID
                unbandOrganizationID: '', //要解绑的目标组织ID

                //组织模块-使用的相关变量
                //author：刘勇文
                //功能 1
                organizationArray: [], //查询组织使用的数组

                //功能 2
                showDetailOrganizationID: '', //要展示组织配置详情的组织ID

                departmentAndMeterRoleDataByOrganizationID: [], //根据组织ID查询当前系统的角色 所用的数组

                otherMeterRoleDataByOrganization: [], //根据组织ID查询当前组织关联的角色 所用的数组

                //功能3
                dialogVisibleOrganizationEdit: false, //绑定和解绑组织和角色弹框 显示/关闭 控制标识符

                bandRoleID_Organization: '', //要绑定的角色ID
                unbandRoleID_Organization: '', //要解绑的角色ID

                bandOrganizationID_Organization: '', //要绑定的组织ID
                unbandOrganizationID_Organization: '', //要解绑的组织ID


                //部门职位模块-使用的相关变量

                //功能1：部门选择下拉框
                departmentSelectList:[],//部门选择下拉框 使用的数组
                departmentID_departmentJob:'',//部门选择下拉框 保存选择的部门ID使用的变量

                //功能2：部门职位选择框
                departmentJobArray:[],//部门职位选择框 使用额数组


                //功能3: 展示部门职位-组织配置详情
                showDetailDepartmentID:'',//要展示部门职位-组织配置详情的部门ID
                showDetailJobID:'',//要展示部门职位-组织配置详情的职位ID

                departmentAndMeterOrganizationDataByDepartmentJob:[],//根据部门职位ID查询当前系统组织 使用的数组

                otherMeterOrganizationDataByDepartmentJob:[],//根据部门职位ID查询当前部门职位关联的组织 使用的数组

                //功能4：绑定和解绑部门职位-组织
                dialogVisibleDepartmentEdit:false,//绑定和解绑 部门职位和组织 弹框 显示/关闭 控制标识符

                bandDepartmentID_DepartmentJob:'',// 要绑定的部门ID
                bandJobID_DepartmentJob:'',// 要绑定的职位ID
                bandOrganizationID_DepartmentJob:'',// 绑定的组织ID

                unbandDepartmentID_DepartmentJob:'',// 要解绑的部门ID
                unbandJobID_DepartmentJob:'',// 要解绑的职位ID
                unbandOrganizationID_DepartmentJob:'',// 要解绑的组织ID
            };
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.addFrom.userID = userInfo.userID
                this.userID = userInfo.userID
            };
            //this.userConfigUserList();
            // this.userConfigBandingUserAndRole(); // 已调通 绑定用户和角色
            // this.userConfigUnBandingUserAndRole(); // 已调通 解绑用户和角色
            // this.roleConfigCreateRole(); // 已调通 创建角色
            // this.roleConfigUpdateRole(); // 已调通 更新角色
            // this.roleConfigDeleteRole(); // 已调通 删除角色

            // this.userConfigQueryRoleCorrelationRoleGroup(); // 已调通 查询角色关联的角色组
            // this.roleConfigBandingRoleGroupAndRole(); // 已调通 绑定角色和角色组
            // this.roleConfigUnBandingRoleGroupAndRole(); // 已调通 解绑角色和角色组
            // this.userConfigQueryOrganization(); // 已调通 查询系统组织
            // this.userConfigQueryRoleCorrelationOrganization(); // 已调通 查询角色关联的组织
            // this.roleConfigBandingOrganizationAndRole(); // 已调通 绑定角色和组织
            // this.roleConfigUnBandingOrganizationAndRole(); // 已调通 解绑角色和组织
            // this.roleConfigCreateRoleGroup(); // 已调通 添加角色组
            // this.roleConfigUpdateRoleGroup(); // 已调通 更新角色组
            // this.roleConfigDeleteRoleGroup(); // 已调通 删除角色组
            // this.userConfigQueryPermission(); // 已调通 查询系统权限
            // this.userConfigQueryRoleGroupCorrelationPermission(); // 已调通 查询角色组关联的权限
            // this.roleConfigBandingPermissionAndRoleGroup(); // 已调通 绑定角色组和权限
            // this.roleConfigUnBandingPermissionAndRoleGroup(); // 已调通 解绑角色组和权限
            //this.userConfigQueryPage(); // 已调通 查询系统页面
            //this.userConfigQueryRoleGroupCorrelationPage(); // 已调通 查询角色组关联的页面
            // this.roleConfigBandingPageAndRoleGroup(); // 已调通 绑定角色组和页面 接口调用成功但是实际并没有效果
            // this.roleConfigUnBandingPageAndRoleGroup(); // 已调通 解绑角色组和页面
            //this.userConfigGenderSelect(); // 已调通 性别选择
            //this.userConfigJobSelect(); // 已调通 职位选择
            //this.userConfigDepartmentSelect(); // 部门选择

            //权限管理-用户组
            //author：刘勇文
            this.userConfigUserGroupSelect();

            //权限管理-组织
            //author：刘勇文
            this.userConfigOrganizationSelect();

            //权限管理-部门职位
            //author：刘勇文
            this.userConfigDepartmentSelect();

        },
        methods: {
            // 用户管理接口--开始
            // 用户管理接口--结束


            // 用户组接口--开始
            // 用户组接口--结束

            // 角色管理接口--开始
            // 角色管理接口--结束

            // 组织接口--开始
            // 组织接口--结束




            //增加按钮
            add() {
                //将原有数据清空，以备用来新增 用户信息
                //this.addFrom = {};
                this.dialogVisible = true;
            },
            onAdd() {

            },
            //增加--添加按钮
            trueadd() {
                this.userConfigCreateUser();
                //console.log(this.addFrom)
            },
            async userConfigCreateUser() {
                var param = {
                    userName: this.addFrom.userName,
                    gender: this.addFrom.gender,
                    jobTitle: this.addFrom.jobTitle,
                    workNumber: this.addFrom.workNumber,
                    userAccount: this.addFrom.userAccount,
                    phone: this.addFrom.phone,
                    email: this.addFrom.email,
                    departmentID: this.addFrom.departmentID,
                    leaveFlag: this.addFrom.leaveFlag,
                    lockFlag: this.addFrom.lockFlag,
                    lockDate: this.addFrom.lockDate,
                    userPassword: this.addFrom.userPassword,
                    description: this.addFrom.description
                };
                console.log(param)
                const res = await userConfigCreateUser(param);
                console.log(res)

                if (res && res.data && res.data.result) {
                    this.dialogVisible = false;
                    // this.
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });

                    this.userConfigUserList();

                } else {
                    this.$message({
                        showClose: true,
                        message: '添加失败',
                        type: 'error',
                    });
                }
            },
            //删除
            delTableData(userID) {
                this.deleteNum = userID;
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //调用删除接口
                    this.userConfigDeleteUser();
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            //调用删除接口，实现删除
            async userConfigDeleteUser() {
                var param = {
                    userID: this.deleteNum
                }
                console.log(param)
                const res = await userConfigDeleteUser(param);
                console.log(res)
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigUserList();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //编辑（修改）
            //点击‘编辑’按钮 触发方法
            gotoModify(temp) {
                this.updateForm.userID = temp.userID;
                this.updateForm.gender = temp.gender;
                this.updateForm.jobTitle = temp.jobTitle;
                this.updateForm.phone = temp.phone;
                this.updateForm.email = temp.email;
                this.updateForm.departmentID = temp.departmentID;
                this.updateForm.leaveFlag = temp.leaveFlag;
                this.updateForm.lockFlag = temp.lockFlag;
                this.updateForm.userPassword = temp.userPassword;
                this.updateForm.description = temp.userPassword;

                // 打开编辑窗口
                this.dialog2Visible = true;
                this.userConfigQueryRole(this.updateForm.userID); // 已调通 查询角色列表
                this.userConfigQueryUserCorrelationRole(this.updateForm.userID); // 已调通 查询用户关联的角色
            },
            //点击编辑窗口中的 ‘确定’ 触发方法
            trueModify() {
                //调用 修改用户方法
                this.userConfigUpdateUser();
            },
            // 修改用户方法
            //调用 UserConfigUpdateUser 接口，实现修改用户功能 
            async userConfigUpdateUser() {
                //封装参数
                var param = {
                    userID: this.updateForm.userID,
                    gender: this.updateForm.gender,
                    jobTitle: this.updateForm.jobTitle,
                    phone: this.updateForm.phone,
                    email: this.updateForm.email,
                    departmentID: this.updateForm.departmentID,
                    leaveFlag: this.updateForm.leaveFlag,
                    lockFlag: this.updateForm.lockFlag,
                    userPassword: this.updateForm.userPassword,
                    description: this.updateForm.description
                }
                console.log(param);
                const res = await userConfigUpdateUser(param);
                console.log(param);

                if (res && res.data && res.data.result) {
                    this.dialog2Visible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigUserList();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //锁定
            //点击‘锁定’按钮时触发的方法
            lock(id) {
                this.lockUserID = id;
                //调用锁定方法
                this.userConfigLockUser();
            },
            //锁定方法
            //调用 锁定userConfigLockUser接口 实现锁定功能
            async userConfigLockUser() {
                //封装参数
                var param = {
                    userID: this.lockUserID
                };
                console.log(param)
                const res = await userConfigLockUser(param);
                console.log(res)

                //调用分页查询，刷新操作之后的页面
                this.userConfigUserList();
            },
            //查看
            //点击‘查看’按钮时触发的方法
            look(temp) {
                //更新查看数据
                console.log(temp)
                this.detailForm = temp;
                //打开查看弹窗
                this.dialog3Visible = true;
            },
            //点击查看dialog中的‘确定’按钮时触发的方法
            closedialog3() {
                this.dialog3Visible = false;
            },
            //添加
            /*             async userConfigCreateAndUpdateUser() {
                            var param = {
                                userName: this.newFrom.userName,
                                gender: this.newFrom.gender,
                                jobTitle: this.newFrom.jobTitle,
                                workNumber: this.newFrom.work_number,
                                userAccount: this.newFrom.user_account,
                                phone: this.newFrom.phone,
                                email: this.newFrom.email,
                                departmentID: this.newFrom.department_id,
                                leaveFlag: this.newFrom.leave_flag,
                                lockFlag: this.newFrom.lock_flag,
                                lockDate: this.newFrom.lock_date,
                                userPassword: this.newFrom.userPassword,
                                description: this.newFrom.description,
                                userID: this.newFrom.userID,
                            };
                            console.log(param);
                            const res = await userConfigCreateAndUpdateUser(param);
                            console.log(res);
                            if (res && res.data && res.data.result) {
                                this.dialogVisible = false;
                                // this.
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                });
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: '添加失败',
                                    type: 'error',
                                });
                            }
                         },
                         */
            changeTab(id) {
                this.tabIndex = id
                this.currentPage = 1;
                if (this.tabIndex == 1) { // 角色管理
                    this.userConfigQueryRole(); // 已调通 查询角色列表
                    this.userConfigQueryUserCorrelationRole(); // 已调通 查询用户关联的角色
                }
                if (this.tabIndex == 3) { // 角色组
                    //this.userConfigQueryRoleGroup(); // 已调通 查询系统角色组
                }
            },
            handleSelectionChangeDay(val) {
                console.log(val)
                // console.log(val.join())
                this.multipleSelection = val;
                // console.log(this.multipleSelection.join(','))
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.userConfigUserList();
            },

            // 角色管理 -- 开始
            addRole() {
                this.dialogVisibleRoleAdd = true;
                // this.dialogVisibleRole = true
            },
            // 用户-角色配置，绑定
            addUserRole() {
                var id = [];
                for (let i = 0; i < this.nowSelectData.length; i++) {
                    id.push(this.nowSelectData[i].id)
                    var idStr = id.join(",");
                }
                this.userConfigBandingUserAndRole(this.updateForm.userID, idStr);
            },
            // 用户-角色配置，解绑
            removeUserRole() {
                var id = [];
                for (let i = 0; i < this.nowSelectRightData.length; i++) {
                    id.push(this.nowSelectRightData[i].id)
                    var idStr = id.join(",");
                    console.log(idStr)
                }
                this.userConfigUnBandingUserAndRole(this.updateForm.userID, idStr);
            },
            // 查询系统角色
            async userConfigQueryRole(userID) {
                var param = {
                    userID: userID,
                }
                const roleArrayRes = await userConfigQueryRole(param);
                if (roleArrayRes && roleArrayRes.data) {
                    this.userNotHaveRoleArray = roleArrayRes.data;
                } else {
                    // 若查询失败，将数组清空，页面展示“暂无数据”（防止查询失败时，展示的数据是上一个窗口的数据）
                    this.userNotHaveRoleArray = [];
                }
            },
            // 查询用户关联的角色
            async userConfigQueryUserCorrelationRole(userID) {
                var param = {
                    userID: userID,
                }
                const res = await userConfigQueryUserCorrelationRole(param);
                if (res && res.data) {
                    this.userHaveRoleArray = res.data;
                } else {
                    // 若查询失败，将数组清空，页面展示“暂无数据”（防止查询失败时，展示的数据是上一个窗口的数据）
                    this.userHaveRoleArray = [];
                }
            },
            // 绑定用户和角色
            async userConfigBandingUserAndRole(userID, id) {
                var param = {
                    userID: userID,
                    roleID: id
                }
                const res = await userConfigBandingUserAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigQueryRole(this.updateForm.userID);
                    this.userConfigQueryUserCorrelationRole(this.updateForm.userID);
                } else {
                    this.$message.error(res.message);
                }
            },
            // 解绑用户和角色
            async userConfigUnBandingUserAndRole(userID, id) {
                var param = {
                    userID: userID,
                    roleID: id
                }
                const res = await userConfigUnBandingUserAndRole(param);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigQueryRole(this.updateForm.userID);
                    this.userConfigQueryUserCorrelationRole(this.updateForm.userID);
                } else {
                    this.$message.error(res.message);
                }
            },
            // 添加角色
            async roleConfigCreateRole() {
                var param = {
                    roleName: 'lxs测试新增角色2',
                    description: 'lxs测试新增角色222',
                    userID: this.userID
                }
                const selectRes = await roleConfigCreateRole(param);
            },
            // 更新角色
            async roleConfigUpdateRole() {
                var param = {
                    roleID: 'role_f130dbc6-23e7',
                    roleName: 'lxs测试新增角色1-update',
                    description: 'lxs测试新增角色222-update'
                }
                const selectRes = await roleConfigUpdateRole(param);
            },
            // 删除角色
            async roleConfigDeleteRole() {
                var param = {
                    roleID: 'role_02d06039-ebd8'
                }
                const selectRes = await roleConfigDeleteRole(param);
            },
            // 查询角色关联的角色组
            async userConfigQueryRoleCorrelationRoleGroup() {
                var param = {
                    roleID: 'role_zc',
                    // userID: this.userID
                }
                const selectRes = await userConfigQueryRoleCorrelationRoleGroup(param);
            },
            // 绑定角色和角色组
            async roleConfigBandingRoleGroupAndRole() {
                var param = {
                    roleID: 'role_zc',
                    roleGroupID: 'rolegroup_7971-bb9f-31e5'
                }
                const selectRes = await roleConfigBandingRoleGroupAndRole(param);
            },
            // 解绑角色和角色组
            async roleConfigUnBandingRoleGroupAndRole() {
                var param = {
                    roleID: 'role_zc',
                    roleGroupID: 'rolegroup_aa'
                }
                const selectRes = await roleConfigUnBandingRoleGroupAndRole(param);
            },
            // 查询系统组织
            async userConfigQueryOrganization() {
                var param = {
                    roleID: 'role_zz',
                    // userID: 'rolegroup_aa'
                }
                const selectRes = await userConfigQueryOrganization(param);
            },
            // 查询角色关联的组织
            async userConfigQueryRoleCorrelationOrganization() {
                var param = {
                    roleID: 'role_zz',
                    // userID: 'rolegroup_aa'
                }
                const selectRes = await userConfigQueryRoleCorrelationOrganization(param);
            },
            // 绑定角色和组织
            async roleConfigBandingOrganizationAndRole() {
                var param = {
                    roleID: 'role_zz',
                    organizationID: 'P7'
                }
                const selectRes = await roleConfigBandingOrganizationAndRole(param);
            },
            // 解绑角色和组织
            async roleConfigUnBandingOrganizationAndRole() {
                var param = {
                    roleID: 'role_zz',
                    organizationID: 'WS16'
                }
                const selectRes = await roleConfigUnBandingOrganizationAndRole(param);
            },
            // 添加角色组
            async roleConfigCreateRoleGroup() {
                var param = {
                    roleGroupName: 'lxs测试新增角色组2',
                    description: 'lxs测试新增角色组2',
                    userID: this.userID
                }
                const selectRes = await roleConfigCreateRoleGroup(param);
            },
            // 更新角色组
            async roleConfigUpdateRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_2fa9-dc5d-ddf2',
                    roleGroupName: 'lxs测试新增角色组1-update',
                    description: 'lxs测试新增角色组1-update'
                }
                const selectRes = await roleConfigUpdateRoleGroup(param);
            },
            // 删除角色组
            async roleConfigDeleteRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_7bfb-91a0-c90c'
                }
                const selectRes = await roleConfigDeleteRoleGroup(param);
            },
            // 查询系统权限
            async userConfigQueryPermission() {
                var param = {
                    roleGroupID: 'rolegroup_zc',
                    // userID: this.userID
                }
                const selectRes = await userConfigQueryPermission(param);
            },
            // 查询角色组关联的权限
            async userConfigQueryRoleGroupCorrelationPermission() {
                var param = {
                    roleGroupID: 'rolegroup_zc',
                    // userID: this.userID
                }
                const selectRes = await userConfigQueryRoleGroupCorrelationPermission(param);
            },
            // 绑定角色组和权限
            async roleConfigBandingPermissionAndRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_zc',
                    permissionID: 'permission_724fcf22-121c'
                }
                const selectRes = await roleConfigBandingPermissionAndRoleGroup(param);
            },
            // 解绑角色组和权限
            async roleConfigUnBandingPermissionAndRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_zc',
                    permissionID: 'permission_zz'
                }
                const selectRes = await roleConfigUnBandingPermissionAndRoleGroup(param);
            },
            // 查询系统页面
            async userConfigQueryPage() {
                var param = {
                    roleGroupID: 'rolegroup_1',
                    // userID: this.userID
                }
                const selectRes = await userConfigQueryPage(param);
            },
            // 查询角色组关联的页面
            async userConfigQueryRoleGroupCorrelationPage() {
                var param = {
                    roleGroupID: 'rolegroup_1',
                    // userID: this.userID
                }
                const selectRes = await userConfigQueryRoleGroupCorrelationPage(param);
            },
            // 绑定角色组和页面
            async roleConfigBandingPageAndRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_1',
                    pageID: 'page_3'
                }
                const selectRes = await roleConfigBandingPageAndRoleGroup(param);
            },
            // 解绑角色组和页面
            async roleConfigUnBandingPageAndRoleGroup() {
                var param = {
                    roleGroupID: 'rolegroup_1',
                    pageID: 'page_1'
                }
                const selectRes = await roleConfigUnBandingPageAndRoleGroup(param);
            },
            // 性别选择
            async userConfigGenderSelect() {
                var param = {}
                const selectRes = await userConfigGenderSelect(param);
                selectRes.data.forEach(element => {
                    this.sex.push({
                        value: element.id,
                        label: element.name
                    });
                })
            },
            // 职位选择
            async userConfigJobSelect() {
                var param = {}
                const jobRes = await userConfigJobSelect(param);
                jobRes.data.forEach(element => {
                    this.jobArray.push({
                        value: element.id,
                        label: element.name
                    });
                })
            },
            // 部门选择
/*             async userConfigDepartmentSelect() {
                var param = {}
                const departmentRes = await userConfigDepartmentSelect(param);
                departmentRes.data.forEach(element => {
                    this.departmentArray.push({
                        value: element.id,
                        label: element.name
                    });
                })
            }, */
            // 角色管理 -- 结束

            //穿梭框-开始-----------
            tableHeaderColor({
                row,
                column,
                rowIndex,
                columnIndex
            }) { //改表头颜色
                if (rowIndex === 0) {
                    // return 'background-color: #F3F3F3;color: #666;'
                    return 'background-color: #E2F0FE;'
                }
            },
            // 左侧数据全选
            checkAll(val) {
                console.log(val)
                this.nowSelectData = val;
            },
            // 右侧数据全选
            checkRightAll(val) {
                console.log(val)
                this.nowSelectRightData = val;
            },
            // 移除
            handelSelect() {
                this.otherMeterData = this.handleConcatArr(this.otherMeterData, this.nowSelectData); //右侧数据=右侧数据+左侧选中的数据
                this.handleRemoveTabList(this.nowSelectData, this.departmentAndMeterData); //移除左侧选中的数据（左侧选中的数据,左侧数据）
                this.nowSelectData = []; //左侧选中数据为空
            },
            // 添加
            handleRemoveSelect() {
                this.departmentAndMeterData = this.handleConcatArr(this.departmentAndMeterData, this
                    .nowSelectRightData);
                this.handleRemoveTabList(this.nowSelectRightData, this.otherMeterData);
                this.nowSelectRightData = [];
            },
            //右侧数据=右侧数据+左侧选中的数据
            handleConcatArr(a, b) {
                let arr = [];
                arr = arr.concat(a, b);
                return arr; //arr=右侧数据+左侧选中的数据
                // console.log(arr);
            },
            //移除左侧选中的数据
            handleRemoveTabList(isNeedArr, originalArr) { //isNeedArr为左侧选中的数据，originalArr为左侧数据
                if (isNeedArr.length && originalArr.length) { //数据不为空
                    for (let i = 0; i < isNeedArr.length; i++) { //遍历左侧选中的数据
                        for (let k = 0; k < originalArr.length; k++) { //遍历左侧所有的数据
                            // if (isNeedArr[i]["id"] === originalArr[k]["id"]) {//如果左侧选中的数据=左侧其中的一个数据
                            if (isNeedArr[i]["meter_name"] === originalArr[k]["meter_name"]) {
                                originalArr.splice(k, 1); //将左侧所有数据中选中的那个数据删除
                                //splice可删除从 index 处开始的零个或多个元素,并且用参数列表中声明的一个或多个值来替换那些被删除的元素
                            }
                        }
                    }
                }
            },
            //穿梭框-结束-------------

            //筛选
            //用户信息清单-分页筛选
            async userConfigUserList() {
                //this.query.pageSize = this.pageSize;
                //this.query.pageQuantity = this.pageQuantity;
                //this.query.currentPage = this.currentPage;
                //this.query.userID = this.userID;
                var param = {
                    query: this.query,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage
                }
                console.log(param)
                const res = await userConfigUserList(param);
                console.log(res)
                this.queryList = res.data.rows;
                this.pageQuantity = res.data.pageQuantity;
            },
            changeLockDateReadOnly() {
                if (this.addFrom.lockFlag == 0) {
                    this.lockDateReadOnly = true;
                } else {
                    this.lockDateReadOnly = false;
                }
            },


            // 角色管理---开始
            roleEdit() {
                this.dialogVisibleRoleEdit = true
            },
            roleLocking() {
                this.$confirm('此操作为锁定该角色, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            roleDel() {
                this.$confirm('此操作将永久删除该角色组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 角色管理---结束
            // 






            //用户组模块-使用的相关方法
            //author：刘勇文

            //功能 1：查询用户组
            //1.1 查询用户组方法
            async userConfigUserGroupSelect() {

                //封装参数
                var param = {};

                //调用接口
                const selectRes = await userConfigUserGroupSelect(param);
                console.log(selectRes)

                //处理返回值，讲返回值装载到查询用户组数组中
                if (selectRes) { //如果有返回值则赋值
                    this.roleGroupArray = selectRes.data;
                    console.log(this.roleGroupArray)
                }
                //转换时间格式
                for (var i = 0; i < this.roleGroupArray.length; i++) {
                    let data = this.roleGroupArray[i].userGroupCreateDate;
                    this.roleGroupArray[i].userGroupCreateDate = this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
                }

            },

            //功能 2：添加用户组
            //2.1 点击 ‘添加用户组’ 按钮时触发的方法 
            addRoleGroup() {

                //（1）初始化添加用户组所用的数组，以备用来添加新的用户组
                this.addRoleGroupForm = {};
                //打开添加用户组弹框
                this.dialogVisibleRoleGroupAdd = true;
            },
            //2.2 点击 添加用户组弹框中的 ‘添加’ 按钮时触发的方法
            onAdd() {

                //调用添加用户组方法
                this.roleConfigCreateUserGroup();
            },
            //2.3 添加用户组的方法
            async roleConfigCreateUserGroup() {

                //封装参数
                var param = {
                    userGroupName: this.addRoleGroupForm.userGroupName,
                    description: this.addRoleGroupForm.userGroupDescription,
                };
                console.log(param)

                //调用接口
                const res = await roleConfigCreateUserGroup(param);
                console.log(res)

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.dialogVisibleRoleGroupAdd = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigUserGroupSelect();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //功能 3：删除用户组
            //3.1 点击左侧表中的‘删除’按钮时触发的方法
            roleGroupDel(id) {
                this.$confirm('此操作将永久删除该用户组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    /*                     this.$message({
                                            type: 'success',
                                            message: '删除成功!'
                                        }); */

                    //调用删除用户组方法，进行用户组的删除
                    this.deleteUserGroupID = id;
                    this.roleConfigDeleteUserGroup();

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //3.2 删除用户组的方法
            async roleConfigDeleteUserGroup() {

                //封装参数
                var param = {
                    userGroupID: this.deleteUserGroupID,
                };

                //调用接口
                const res = await roleConfigDeleteUserGroup(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.userConfigUserGroupSelect();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //功能 4：更新用户组信息
            //4.1 点击左侧表中的‘更新’按钮时触发的方法
            roleGroupEdit(data) {

                //初始化更新用户组信息所使用的数组，以便用来更新用户组信息
                this.updateRoleGroupForm.userGroupID = data.userGroupID;
                this.updateRoleGroupForm.userGroupName = data.userGroupName;
                this.updateRoleGroupForm.description = data.userGroupDesc;

                //初始化更新用户组绑定信息所使用的数据
                //展示
                this.showDetailsuserGroupID = data.userGroupID;
                //调用 根据用户组ID查询系统用户 方法
                this.userConfigQueryUserByUserGroupID();
                //调用 根据用户组ID查询系统角色 方法
                this.userConfigQueryRoleByUserGroupID();
                //调用 根据用户组ID查询系统组织 方法
                this.userConfigQueryOrganizationByUserGroupID();
                //调用 根据用户组ID查询用户组关联的用户 方法
                this.userConfigQueryUserGroupCorrelationUser();
                //调用 根据用户组ID查询用户组关联的角色 方法
                this.userConfigQueryUserGroupCorrelationRole();
                //调用 根据用户组ID查询用户组关联的组织 方法
                this.userConfigQueryUserGroupCorrelationOrganization();

                //绑定和解绑
                this.bandUserGroupID = data.userGroupID;
                this.unbandUserGroupID = data.userGroupID;

                //打开更新用户组信息弹框
                this.dialogVisibleRoleGroupEdit = true;
            },
            //4.2 点击更新用户组信息弹框中的‘添加’按钮时触发的方法
            trueEdit() {

                //调用更新用户组信息方法，进行用户组信息的更新
                this.roleConfigUpdateUserGroup();
            },
            //4.3 更新用户组信息方法
            async roleConfigUpdateUserGroup() {

                //封装参数
                var param = {
                    userGroupID: this.updateRoleGroupForm.userGroupID,
                    userGroupName: this.updateRoleGroupForm.userGroupName,
                    description: this.updateRoleGroupForm.description,
                };

                //调用接口
                const res = await roleConfigUpdateUserGroup(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    //关闭更新用户组信息弹框
                    this.dialogVisibleRoleGroupEdit = false;
                    //调用查询用户组方法，刷新更新之后的数据
                    this.userConfigUserGroupSelect();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //功能 5：首页展示穿梭框 循环展示控制
            //5.1 点击首演展示穿梭框中 ‘左箭头’按钮 和 ‘右箭头’按钮时触发的方法
            userGroupDetailsShowControl(flag) {

                if (flag) { //flag等于true时，页面向左循环展示
                    this.userGroupDetailsShow--;
                    if (this.userGroupDetailsShow == 0)
                        this.userGroupDetailsShow = 3;
                } else { //flag等于false时，页面
                    this.userGroupDetailsShow++;
                    if (this.userGroupDetailsShow == 4)
                        this.userGroupDetailsShow = 1;
                }

            },

            //功能 6：用户组配置信息详情展示
            //6.1 点击左侧表中每一个‘用户组模块’时触发的方法
            showDetails(temp) {
                this.showDetailsuserGroupID = temp.userGroupID;

                //调用 根据用户组ID查询系统用户 方法
                this.userConfigQueryUserByUserGroupID();
                //调用 根据用户组ID查询系统角色 方法
                this.userConfigQueryRoleByUserGroupID();
                //调用 根据用户组ID查询系统组织 方法
                this.userConfigQueryOrganizationByUserGroupID();
                //调用 根据用户组ID查询用户组关联的用户 方法
                this.userConfigQueryUserGroupCorrelationUser();
                //调用 根据用户组ID查询用户组关联的角色 方法
                this.userConfigQueryUserGroupCorrelationRole();
                //调用 根据用户组ID查询用户组关联的组织 方法
                this.userConfigQueryUserGroupCorrelationOrganization();
            },
            //6.2 根据用户组ID展示当前系统用户的方法
            async userConfigQueryUserByUserGroupID() {

                //封装参数
                var param = {
                    userGroupID: this.showDetailsuserGroupID,
                };

                //调用接口
                const selectRes = await userConfigQueryUserByUserGroupID(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.departmentAndMeterUserData = selectRes.data;
                }
            },
            //6.3 根据用户组ID展示当前系统角色的方法
            async userConfigQueryRoleByUserGroupID() {

                //封装参数
                var param = {
                    userGroupID: this.showDetailsuserGroupID,
                };

                //调用接口
                const selectRes = await userConfigQueryRoleByUserGroupID(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.departmentAndMeterRoleData = selectRes.data;
                }
            },
            //6.4 根据用户组Id展示当前系统组织的方法
            async userConfigQueryOrganizationByUserGroupID() {

                //封装参数
                var param = {
                    userGroupID: this.showDetailsuserGroupID,
                };

                //调用接口
                const selectRes = await userConfigQueryOrganizationByUserGroupID(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.departmentAndMeterGroupData = selectRes.data;
                }
            },
            //6.5 根据用户组ID查询用户组关联的用户的方法
            async userConfigQueryUserGroupCorrelationUser() {

                //封装参数
                var param = {

                    userGroupID: this.showDetailsuserGroupID,
                    //userGroupID:'usergroup_a',
                };

                //调用接口
                const selectRes = await userConfigQueryUserGroupCorrelationUser(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.otherMeterUserData = selectRes.data;
                }
            },
            //6.6 根据用户组ID查询用户组关联的角色的方法
            async userConfigQueryUserGroupCorrelationRole() {

                //封装参数
                var param = {

                    userGroupID: this.showDetailsuserGroupID,
                    //userGroupID:'usergroup_a',
                };

                //调用接口
                const selectRes = await userConfigQueryUserGroupCorrelationRole(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.otherMeterRoleData = selectRes.data;
                }
            },
            //6.7 根据用户组ID查询用户组关联的组织的方法
            async userConfigQueryUserGroupCorrelationOrganization() {

                //封装参数
                var param = {
                    userGroupID: this.showDetailsuserGroupID,
                };

                //调用接口
                const selectRes = await userConfigQueryUserGroupCorrelationOrganization(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.otherMeterGroupData = selectRes.data;
                }
            },

            //功能 7：绑定和解绑用户组和角色
            //7.1 选择要绑定的角色ID
            selectBandRoleID(role) {

                var temp = [];
                for (var i = 0; i < role.length; i++) {
                    temp.push(role[i].id);
                }

                //console.log(temp)
                this.bandRoleID = temp.join(",");

                //console.log(this.bandRoleID) 
            },
            //7.2 点击绑定用户组和角色‘向左箭头’时触发的方法
            bandRole() {

                //调用绑定用户组和角色的方法
                this.userConfigBandingUserGroupAndRole();
            },
            //7.3 绑定用户组和角色的方法
            async userConfigBandingUserGroupAndRole() {

                //封装参数
                var param = {
                    userGroupID: this.bandUserGroupID,
                    roleID: this.bandRoleID,
                };

                //调用接口
                const res = await userConfigBandingUserGroupAndRole(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据根据用户组ID查询系统角色方法 刷新绑定之后的页面数据
                    this.userConfigQueryRoleByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的角色方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationRole();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }

            },
            //7.4 选择要解绑的角色ID
            selectUnbandRoleID(role) {

                var temp = [];
                for (var i = 0; i < role.length; i++) {
                    temp.push(role[i].id);
                }

                this.unbandRoleID = temp.join(",");

            },
            //7.5 点击解绑用户组和角色‘向右箭头’时触发的方法
            unbandRole() {

                //调用解绑用户组和角色的方法
                this.userConfigUnBandingUserGroupAndRole();
            },
            //7.6 解绑用户组和角色的方法
            async userConfigUnBandingUserGroupAndRole() {

                //封装参数
                var param = {
                    userGroupID: this.unbandUserGroupID,
                    roleID: this.unbandRoleID,
                };

                //调用接口
                const res = await userConfigUnBandingUserGroupAndRole(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据根据用户组ID查询系统角色方法 刷新绑定之后的页面数据
                    this.userConfigQueryRoleByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的角色方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationRole();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //功能 8：绑定和解绑用户组和用户
            //8.1 选择要绑定的用户ID
            selectBandUserID(user) {

                var temp = [];
                for (var i = 0; i < user.length; i++) {
                    temp.push(user[i].id);
                }

                this.bandUserID = temp.join(",");
            },
            //8.2 点击绑定用户组和用户‘向左箭头’时触发的方法
            bandUser() {

                //调用绑定用户组和用户的方法
                this.userConfigBandingUserGroupAndUser();
            },
            //8.3 绑定用户组和用户的方法
            async userConfigBandingUserGroupAndUser() {

                //封装参数
                var param = {
                    userGroupID: this.bandUserGroupID,
                    userID: this.bandUserID,
                };

                //调用接口
                const res = await userConfigBandingUserGroupAndUser(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据用户组ID查询系统用户方法 刷新绑定之后的页面数据
                    this.userConfigQueryUserByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的用户方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationUser();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }

            },
            //8.4 选择要解绑的用户ID
            selectUnbandUserID(user) {

                var temp = [];
                for (var i = 0; i < user.length; i++) {
                    temp.push(user[i].id);
                }

                this.unbandUserID = temp.join(",");
            },
            //8.5 点击解绑用户组和用户‘向左箭头’时触发的方法
            unbandUser() {

                //调用解绑用户组和用户的方法
                this.userConfigUnBandingUserGroupAndUser();
            },
            //8.6 解绑用户组和用户的方法
            async userConfigUnBandingUserGroupAndUser() {

                //封装参数
                var param = {
                    userGroupID: this.unbandUserGroupID,
                    userID: this.unbandUserID,
                };

                //调用接口
                const res = await userConfigUnBandingUserGroupAndUser(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据用户组ID查询系统用户方法 刷新绑定之后的页面数据
                    this.userConfigQueryUserByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的用户方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationUser();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },


            //功能 9：绑定和解绑用户组和组织
            //9.1 选择要绑定的组织ID
            selectBandOrganizationID(organization) {

                var temp = [];
                for (var i = 0; i < organization.length; i++) {
                    temp.push(organization[i].id);
                }

                this.bandOrganizationID = temp.join(",");
            },
            //9.2 点击绑定用户组和组织‘向左箭头’时触发方法
            bandOrganization() {

                //调用绑定用户组和组织的方法
                this.userConfigBandingUserGroupAndOrganization();
            },
            //9.3 绑定用户组和组织的方法
            async userConfigBandingUserGroupAndOrganization() {

                //封装参数
                var param = {
                    userGroupID: this.bandUserGroupID,
                    organizationID: this.bandOrganizationID,
                };

                //调用接口
                const res = await userConfigBandingUserGroupAndOrganization(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据根据用户组ID查询系统组织方法 刷新绑定之后的页面数据
                    this.userConfigQueryOrganizationByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的组织方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationOrganization();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //9.4 选择要解绑的组织ID
            selectUnbandOrganizationID(organization) {

                var temp = [];
                for (var i = 0; i < organization.length; i++) {
                    temp.push(organization[i].id);
                }

                this.unbandOrganizationID = temp.join(",");
            },
            //9.5 点击解绑用户组和组织‘向右箭头’时触发的方法
            unbandOrganization() {

                //调用解绑用户组和组织的方法
                this.userConfigUnBandingUserGroupAndOrganization();
            },
            //9.6 解绑用户组和组织的方法
            async userConfigUnBandingUserGroupAndOrganization() {

                //封装参数
                var param = {
                    userGroupID: this.unbandUserGroupID,
                    organizationID: this.unbandOrganizationID,
                };

                //调用接口
                const res = await userConfigUnBandingUserGroupAndOrganization(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据根据用户组ID查询系统组织方法 刷新绑定之后的页面数据
                    this.userConfigQueryOrganizationByUserGroupID();
                    //调用根据用户组ID查询当前用户组关联的组织方法 刷新绑定之后页面数据
                    this.userConfigQueryUserGroupCorrelationOrganization();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //组织模块-使用的相关方法
            //author：刘勇文

            //功能 1：查询组织
            //1.1 查询组织的方法
            async userConfigOrganizationSelect() {

                //封装参数
                var param = {};

                //调用接口
                const selectRes = await userConfigOrganizationSelect(param);

                //处理返回值，将返回值装载到查询组织所用的数组中
                if (selectRes) { //如果有返回值
                    this.organizationArray = selectRes.data;
                }

                //转换时间格式
                for (var i = 0; i < this.organizationArray.length; i++) {
                    let data = this.organizationArray[i].createDate;
                    this.organizationArray[i].createDate = this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
                }
            },

            //功能 2：组织配置信息详情展示
            //2.1 点击左侧列表中每一个‘组织’模块时触发的方法
            showOrganizationDetails(temp) {
                this.showDetailOrganizationID = temp.id;

                //调用 根据组织ID查询系统角色 方法
                this.userConfigQueryRoleByUserOrganizationID();

                //调用 根据组织ID查询当前组织关联的角色 方法
                this.userConfigQueryOrganizationCorrelationRole();
            },
            //2.2 根据组织ID查询当前系统的角色的方法
            async userConfigQueryRoleByUserOrganizationID() {

                //封装参数
                var param = {
                    organizationID: this.showDetailOrganizationID,
                };

                //调用接口
                const selectRes = await userConfigQueryRoleByUserOrganizationID(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.departmentAndMeterRoleDataByOrganizationID = selectRes.data;
                }
            },
            //根据组织ID查询当前组织关联的角色 方法
            async userConfigQueryOrganizationCorrelationRole() {

                //封装参数
                var param = {
                    organizationID: this.showDetailOrganizationID,
                };

                //调用接口
                const selectRes = await userConfigQueryOrganizationCorrelationRole(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.otherMeterRoleDataByOrganization = selectRes.data;
                }
            },

            //功能 3：绑定和解绑组织和角色
            //3.1 点击左侧‘编辑’按钮时触发的方法
            organizationEdit(data) {

                //初始化绑定和解绑组织和角色 弹框中所使用的数据
                this.showDetailOrganizationID = data.id;

                //调用 根据组织ID查询当前系统的角色方法
                this.userConfigQueryRoleByUserOrganizationID();

                //调用 根据组织ID查询当前组织关联的角色方法
                this.userConfigQueryOrganizationCorrelationRole();

                //绑定和解绑
                this.bandOrganizationID_Organization = data.id;
                this.unbandOrganizationID_Organization = data.id;

                //打开绑定和解绑组织和角色 弹框
                this.dialogVisibleOrganizationEdit = true;
            },

            //3.2 选择要绑定的角色ID
            selectBandRoleID_Organization(role) {

                var temp = [];
                for (var i = 0; i < role.length; i++) {
                    temp.push(role[i].id);
                }

                this.bandRoleID_Organization = temp.join(",");
            },

            //3.3 点击绑定组织和角色‘向右箭头’时触发的方法
            bandRole_Organization() {

                //调用绑定组织和角色的方法
                this.userConfigBandingOrganizationAndRole();
            },

            //3.4 绑定组织和角色的方法
            async userConfigBandingOrganizationAndRole() {


                //封装参数
                var param = {
                    organizationID: this.bandOrganizationID_Organization,
                    roleID: this.bandRoleID_Organization,
                };

                //调用接口
                const res = await userConfigBandingOrganizationAndRole(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据组织ID查询当前系统角色方法 刷新绑定之后的页面数据
                    this.userConfigQueryRoleByUserOrganizationID();
                    //调用根据组织ID查询当前组织关联的角色的方法 刷新绑定之后的页面数据
                    this.userConfigQueryOrganizationCorrelationRole();

                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            //3.5 选择要解绑的角色ID
            selectUnbandRoleID_Organization(role) {

                var temp = [];
                for (var i = 0; i < role.length; i++) {
                    temp.push(role[i].id);
                }

                this.unbandRoleID_Organization = temp.join(",");
                console.log(this.unbandRoleID_Organization)
            },

            //3.6 点击解绑组织和角色‘向右箭头’时触发的方法
            unbandRole_Organization() {

                //调用解绑组织和角色的方法
                this.userConfigUnBandingOrganizationAndRole();
            },

            //3.7 解绑组织和角色的方法
            async userConfigUnBandingOrganizationAndRole() {

                //封装参数
                var param = {
                    organizationID: this.unbandOrganizationID_Organization,
                    roleID: this.unbandRoleID_Organization,
                };

                //调用接口
                const res = await userConfigUnBandingOrganizationAndRole(param);

                //处理返回值
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据组织ID查询当前系统角色方法 刷新绑定之后的页面数据
                    this.userConfigQueryRoleByUserOrganizationID();
                    //调用根据组织ID查询当前组织关联的角色的方法 刷新绑定之后的页面数据
                    this.userConfigQueryOrganizationCorrelationRole();

                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },

            
            //部门职位模块-使用的方法
            //author：刘勇文

            //功能1：部门选择下下拉框
            //1.1 部门选择框方法
            //此方法 页面一加载就立即调用
            async userConfigDepartmentSelect(){
                
                //将部门选择下拉框中使用的数组 初始化
                this.departmentSelectList = [];

                //封装参数
                var param = {};

                //调用接口
                const selectRes = await userConfigDepartmentSelect();

                //处理返回值
                if(selectRes){//如果有返回值
                    //console.log('这是部门选择下拉框')
                    //console.log(selectRes)
                    this.departmentSelectList = selectRes.data.rows
                }
            },

            //功能2：部门职位选择框
            //2.1 选中部门ID时触发的方法 
            queryDepartmentJob(){
                
                //调用查询部门职位的方法 查询选中的部门的部门职位
                this.userConfigDepartmentJobSelect();
            },
            //2.2 查询部门职位的方法
            async userConfigDepartmentJobSelect(){
                

                //封装参数
                var param = {
                    departmentID:this.departmentID_departmentJob,
                };

                //调用接口
                const selectRes = await userConfigDepartmentJobSelect(param);

                //处理返回值
                if(selectRes){//如果有返回值
                    this.departmentJobArray = selectRes.data;
                    console.log('这是部门职位')
                    console.log(this.departmentJobArray)
                }
            },

            //功能3：部门职位-组织配置信息详情展示
            //3.1 点击左侧表中每一个‘部门职位’模块时触发的方法
            showDepartmentJobDetails(temp){
                
                if(temp!=null){

                    console.log(temp)
                    this.showDetailDepartmentID = this.departmentID_departmentJob;
                    this.showDetailJobID = temp.jobID;

                    //调用根据部门职位ID查询系统的组织 方法
                    this.userConfigQueryOrganizationByUserDepartmentJobID();

                    //调用根据部门职位ID查询当前部门职位关联的组织 方法
                    this.userConfigQueryDepartmentJobCorrelationOrganization();
                }
            },
            //3.2 根据部门职位ID查询系统的组织 的 方法
            async userConfigQueryOrganizationByUserDepartmentJobID(){

                //封装参数
                var param = {
                    jobID:this.showDetailJobID,
                    departmentID:this.showDetailDepartmentID,
                };

                //调用接口
                const selectRes = await userConfigQueryOrganizationByUserDepartmentJobID(param);

                //处理返回值
                if(selectRes){//如果有返回值
                    //console.log(selectRes.data)
                    this.departmentAndMeterOrganizationDataByDepartmentJob = selectRes.data;
                    console.log(this.departmentAndMeterOrganizationDataByDepartmentJob)
                }
            },
            //3.3 根据部门职位ID查询当前部门职位关联的组织 的 方法
            async userConfigQueryDepartmentJobCorrelationOrganization(){
                
                //封装参数
                var param = {
                    jobID:this.showDetailJobID,
                    departmentID:this.showDetailDepartmentID,
                };

                //调用接口
                const selectRes = await userConfigQueryDepartmentJobCorrelationOrganization(param);

                //处理返回值
                if(selectRes){//如果有返回值
                    console.log(selectRes)
                    this.otherMeterOrganizationDataByDepartmentJob = selectRes.data;
                }
            },

            //功能 4：绑定和解绑部门职位和组织
            //4.1 点击左侧‘编辑’按钮时触发的方法
            departmentJobEdit(data){
                
                console.log(data.jobID)
                //初始化绑定和解绑 部门职位和组织 弹框中使用的数据
                this.showDetailJobID = data.jobID;

                //调用根据部门职位ID查询系统的组织 方法
                this.userConfigQueryOrganizationByUserDepartmentJobID();
                //调用根据部门职位ID查询当前部门职位关联的组织 方法
                this.userConfigQueryDepartmentJobCorrelationOrganization();

                //绑定和解绑
                this.bandDepartmentID_DepartmentJob = this.departmentID_departmentJob;
                this.bandJobID_DepartmentJob = data.jobID;

                this.unbandDepartmentID_DepartmentJob = this.departmentID_departmentJob;
                this.unbandJobID_DepartmentJob = data.jobID;

                //
                this.dialogVisibleDepartmentEdit = true;
            },
            //4.2 选择要绑定的组织ID
            selectBandOrganizationID_DepartmentJob(organization){

                var temp = [];
                for(var i = 0; i < organization.length; i++){
                    temp.push(organization[i].id);
                }

                this.bandOrganizationID_DepartmentJob = temp.join(",");
            },
            //4.3 点击绑定部门职位和组织‘向右箭头’时触发的方法
            bandOrganization_DepartmentJob(){
                
                this.userConfigBandingDepartmentJobAndOrganization();
            },
            //4.4 绑定部门职位和组织的方法
            async userConfigBandingDepartmentJobAndOrganization(){
                
                var param = {
                    jobID:this.bandJobID_DepartmentJob,
                    departmentID:this.bandDepartmentID_DepartmentJob,
                    organizationID:this.bandOrganizationID_DepartmentJob,
                };

                const res = await userConfigBandingDepartmentJobAndOrganization(param);

                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据部门职位ID查询系统的组织 方法
                    this.userConfigQueryOrganizationByUserDepartmentJobID();
                    //调用根据部门职位ID查询当前部门职位关联的组织 方法
                    this.userConfigQueryDepartmentJobCorrelationOrganization();

                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //4.5 选择要解绑的组织ID
            selectUnbandOrganizationID_DepartmentJob(organization){

                var temp = [];
                for(var i = 0; i < organization.length; i++){
                    temp.push(organization[i].id);
                }

                this.unbandOrganizationID_DepartmentJob = temp.join(",");
            },
            //4.6 点击解绑部门职位和组织‘向左箭头’时触发的方法
            unbandOrganization_DepartmentJob(){

                this.userConfigUnBandingDepartmentJobAndOrganization();
            },
            //4.7 解绑部门职位和组织的方法
            async userConfigUnBandingDepartmentJobAndOrganization(){

                var param = {
                    jobID:this.unbandJobID_DepartmentJob,
                    departmentID:this.unbandDepartmentID_DepartmentJob,
                    organizationID:this.unbandOrganizationID_DepartmentJob,
                }

                const res = await userConfigUnBandingDepartmentJobAndOrganization(param);

                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });

                    //调用根据部门职位ID查询系统的组织 方法
                    this.userConfigQueryOrganizationByUserDepartmentJobID();
                    //调用根据部门职位ID查询当前部门职位关联的组织 方法
                    this.userConfigQueryDepartmentJobCorrelationOrganization();

                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
        }
    }
</script>
<style scoped>
    .tab {
        width: 140px;
        background: #E6E6E6;
        color: #1A1A1A;
        margin-right: 2px;
    }

    .tab_active {
        background: #009BFD;
        color: #FFFFFF;
    }

    .select_btn {
        width: 120px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .check_btn {
        background-color: #EBEBEB;
        border-color: #EBEBEB;
    }

    .check_btn:hover {
        background-color: #009BFD;
        border-color: #009BFD;
        color: #FFFFFF;
    }

    .width_40 {
        width: 40px;
    }

    .addBtn {
        width: 114px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .shuttleBox {
        height: 320px;
    }
</style>
<style lang="less" scoped>
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 170px;
    }

    ::v-deep .el-input__suffix {
        display: flex;
        align-content: center;
    }

    ::v-deep .el-input__suffix-inner {
        display: flex;
        align-content: center;
    }

    ::v-deep .el-card__header {
        background-color: #E2F0FE;
        height: 40px;
        display: flex;
        align-items: center;
    }

    ::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label {
        display: flex;
    }

    /* // ::v-deep .el-checkbox__input {
    //     display: flex;
    // } */

    ::v-deep .el-transfer-panel {
        width: 250px;
    }

    ::v-deep .el-form-item {
        margin-bottom: 12px;
    }

    /* //  */
    ::v-deep .el-transfer {
        display: flex;
    }

    ::v-deep .el-transfer__buttons {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    ::v-deep .el-button+.el-button {
        margin: 0px;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }

    ::v-deep .el-popover__reference-wrapper {
        display: flex;
        align-items: center;
    }
</style>